import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./AuthContext";
import UserList from "./pages/Users/Users";
import ArticlesPage from "./pages/article-list/article-list";
import ClientItem from "./pages/client-item/client-item";
import ClientList from "./pages/client-list/client-list";
import DashboardPage from "./pages/dashboard/dashboard";
import Industries from "./pages/industries/industries";
import SignInSide from "./pages/login/login";
import Publications from "./pages/publications/publications";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/client/:id" element={<ClientItem />} />
          <Route path="/clients" element={<ClientList />} />
          <Route path="/articles" element={<ArticlesPage />} />
          <Route path="/" element={<SignInSide />} />
          <Route path="/settings/users" element={<UserList />} />
          <Route path="/settings/industries" element={<Industries />} />
          <Route path="/publications" element={<Publications />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
