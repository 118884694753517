import { Card, Drawer, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../AuthContext";
import ArticleItem from "../../components/article-item/article-item";
import ArticlesGridDash from "../../components/articles-grid/article-grid-dashboard";
import Layout from "../../components/layout/layout";
import "./dashboard.css";
import { envEndPoints } from "../../config";
import { Link } from "react-router-dom";

const DashboardPage = () => {
  const { auth, logout } = useAuth();
  const [clients, setClients] = useState([]);
  const [mediaArticles, setMediaArticles] = useState([]);
  const [totalCountClients, setTotalCountClients] = useState(0);
  const [totalCountMediaArticles, setTotalCountMediaArticles] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isArticleDrawerOpen, setIsArticleDrawerOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const parseDate = (dateString) => new Date(dateString);

  const formatDate = (date) =>
    date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

  const fetchData = async () => {
    const urls = [
      `${envEndPoints().baseUrl}/clients/`,
      `${envEndPoints().baseUrl}/articles/?pageSize=50`,
    ];

    try {
      const responses = await Promise.all(urls.map((url) => fetch(url)));
      const data = await Promise.all(
        responses.map((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
      );

      setClients(data[0].clients);
      setTotalCountClients(data[0].total_count);
      setMediaArticles(data[1].articles);
      setTotalCountMediaArticles(data[1].total_count);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const columns = [
    { field: "clientName", headerName: "Client", width: 130 },
    { field: "sentimentRating", headerName: "Sentiment", width: 120 },
    { field: "publicationName", headerName: "Publication", width: 200 },
    {
      field: "publishedDate",
      headerName: "Date",
      width: 200,
      renderCell: (params) => (
        <span>{formatDate(parseDate(params.value))}</span>
      ),
    },
    { field: "title", headerName: "Title", width: 400 },
  ];

  const handleRowClick = (article) => {
    setSelectedArticle(article);
    setIsArticleDrawerOpen(true);
  };

  const handleDrawerClose = (drawerType) => {
    if (drawerType === "article") {
      setIsArticleDrawerOpen(false);
    }
  };

  return (
    <Layout>
      <Grid container spacing={3}>
        {/* <Grid item xs={12}>
          <Typography variant="h5">Dashboard</Typography>
        </Grid> */}
        <Grid item xs={6}>
          <Card className="padded">
            <Typography variant="body2" sx={{ minWidth: 100 }}>
              Total Clients
            </Typography>

            <Typography variant="h2" sx={{ minWidth: 100 }}>
              {totalCountClients}
            </Typography>

            <Link to="/clients">View Clients</Link>
            {/* <a href="/clients">View Clients</a> */}
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className="padded">
            <Typography variant="body2" sx={{ minWidth: 100 }}>
              Total Media Articles
            </Typography>
            <Typography variant="h2" sx={{ minWidth: 100 }}>
              {totalCountMediaArticles}
            </Typography>
            <Link to="/articles">View Media Articles</Link>

            {/* <a href="/media-articles">View Media Articles</a> */}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 400, width: "100%" }}>
            <ArticlesGridDash
              onArticleSelect={handleRowClick}
              setSelectedArticle={setSelectedArticle}
              setIsArticleDrawerOpen={setIsArticleDrawerOpen}
            />
          </div>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={isArticleDrawerOpen}
        onClose={() => handleDrawerClose("article")}
      >
        <div className="articleSlide">
          <ArticleItem article={selectedArticle} />
        </div>
      </Drawer>
    </Layout>
  );
};

export default DashboardPage;
