import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CasesOutlinedIcon from "@mui/icons-material/CasesOutlined";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext"; // Ensure the path is correct

function MainListItems() {
  return (
    <React.Fragment>
      <Link
        to="/dashboard"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <SpaceDashboardOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </Link>
    </React.Fragment>
  );
}

function SecondaryListItems() {
  return (
    <React.Fragment>
      <Link to="/clients" style={{ textDecoration: "none", color: "inherit" }}>
        <ListItemButton>
          <ListItemIcon>
            <CasesOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Clients" />
        </ListItemButton>
      </Link>
      <Link to="/articles" style={{ textDecoration: "none", color: "inherit" }}>
        <ListItemButton>
          <ListItemIcon>
            <ArticleOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Media Articles" />
        </ListItemButton>
      </Link>
    </React.Fragment>
  );
}

function ThirdListItems() {
  return (
    <React.Fragment>
      <Link
        to="/settings/users"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <PeopleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>
      </Link>
      <Link
        to="/settings/industries"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <DiscountOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Industries" />
        </ListItemButton>
      </Link>
      <Link
        to="/publications"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <NewspaperOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Publications" />
        </ListItemButton>
      </Link>
    </React.Fragment>
  );
}
function FourthListItems() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <React.Fragment>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </React.Fragment>
  );
}

export { FourthListItems, MainListItems, SecondaryListItems, ThirdListItems };
