import { Box, CircularProgress } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { envEndPoints } from "../../config";

function IndustriesGrid() {
  const [industries, setIndustries] = useState([]);
  const [loadingIndustries, setLoadingIndustries] = useState(true);

  useEffect(() => {
    fetchIndustries();
  }, []);

  const fetchIndustries = async () => {
    setLoadingIndustries(true);
    try {
      const response = await fetch(`${envEndPoints().baseUrl}/industries/`);
      const data = await response.json();
      setIndustries(data.industries); // Access the "industries" array from the response
    } catch (error) {
      console.error("Failed to fetch industries:", error);
    }
    setLoadingIndustries(false);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, editable: false },
    { field: "name", headerName: "Industry", width: 200, editable: false },
    {
      field: "client_count",
      headerName: "Clients",
      width: 200,
      editable: false,
    },
  ];

  if (loadingIndustries) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGridPro
        rows={industries}
        columns={columns}
        pageSize={25}
        pageSizeOptions={(10, 25, 50)}
      />
    </Box>
  );
}

export default IndustriesGrid;
