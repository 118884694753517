import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import "./article-items.css";
import { envEndPoints } from "../../config";

function SentimentChangeButton({ article }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSentimentChange = (newSentiment) => {
    console.log("Changing sentiment to:", newSentiment); // Log to check value
    console.log(
      `URL:${envEndPoints().baseUrl}/patcharticles/sentiment/${article.id}`
    ); // Log to check URL construction

    fetch(`${envEndPoints().baseUrl}/patcharticles/sentiment/${article.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ newSentiment: newSentiment }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        handleClose(); // Close the menu after the request
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <Button
        color="primary"
        variant="outlined"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ChangeCircleOutlinedIcon />
        Change Sentiment
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSentimentChange(1)}>Positive</MenuItem>
        <MenuItem onClick={() => handleSentimentChange(2)}>Neutral</MenuItem>
        <MenuItem onClick={() => handleSentimentChange(3)}>Negative</MenuItem>
        <MenuItem onClick={handleClose} style={{ color: "red" }}>
          Cancel
        </MenuItem>
      </Menu>
    </div>
  );
}

export default SentimentChangeButton;
