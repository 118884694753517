import {
  ThumbDownOutlined as ThumbDownOutlinedIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
} from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import Axios from "axios";
import React, { useState } from "react";
import "./article-items.css";
import { envEndPoints } from "../../config";

function ChangeRelevanceButtons({ article, onCloseDrawer }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [relevance, setRelevance] = useState(null); // Initialize to null
  const [currentSentiment, setCurrentSentiment] = useState(null);

  const handleSetRelevance = async (isRelevant) => {
    setLoading(true);
    setError(null);

    try {
      const response = await Axios.patch(
        `${envEndPoints().baseUrl}/patcharticles/relevant/?id=${article.id}&is_relevant=${isRelevant}`,
        {
          media_article_id: article.id,
          is_relevant: isRelevant,
        }
      );

      // Update relevance state after successful API call
      setRelevance(isRelevant);
      setLoading(false);
    } catch (error) {
      setError(error.message || "An error occurred");
      setLoading(false);
    }
  };

  return (
    <span>
      <div>&nbsp;</div>
      <Button
        variant="outlined"
        color={relevance === 0 ? "error" : "primary"}
        onClick={() => handleSetRelevance(0)}
        disabled={loading} // Disable when loading or relevance is already 0
      >
        <ThumbDownOutlinedIcon />
        &nbsp; This is&nbsp; <strong>not</strong> &nbsp;relevant
      </Button>
      <Button
        variant={relevance === 1 ? "contained" : "outlined"}
        color={relevance === 1 ? "success" : "primary"}
        onClick={() => handleSetRelevance(1)}
        disabled={loading}
      >
        <ThumbUpOutlinedIcon />
        &nbsp; This is relevant
      </Button>

      {loading && <CircularProgress size={24} />}
      {error && <p>Error: {error}</p>}
    </span>
  );
}

export default ChangeRelevanceButtons;
