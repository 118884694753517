import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Drawer,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/layout/layout";
import "./client-list.css";
import { envEndPoints } from "../../config";
const AddClient = React.lazy(() => import("./add-client"));

function ClientList() {
  const [clients, setClients] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const fetchData = async () => {
    console.log("fetchData called");
    const url = `${envEndPoints().baseUrl}/clients/`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const formattedClients = data.clients.map((client) => ({
        ...client,
        industries:
          client.industries && client.industries.length > 0
            ? client.industries.join(", ")
            : "None",
        owners:
          client.owners && client.owners.length > 0
            ? client.owners.join(", ")
            : "None",
      }));
      setClients(formattedClients);
    } catch (error) {
      console.error("Fetching error: ", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("useEffect called");
    fetchData();
  }, []);

  const columns = [
    { field: "name", headerName: "Client", width: 130 },
    {
      field: "sentiment",
      headerName: "Overall Sentiment",
      renderCell: (params) => {
        const chipClass =
          params.value === "Positive"
            ? "chip-positive"
            : params.value === "Neutral"
            ? "chip-neutral"
            : params.value === "Negative"
            ? "chip-negative"
            : "";
        return <Chip label={params.value} className={chipClass} />;
      },
      width: 200,
    },
    { field: "industries", headerName: "Industries", width: 230 },
    { field: "owners", headerName: "Owners", width: 220 },
    { field: "description", headerName: "Description", width: 400 },
    {
      field: "details",
      headerName: "Details",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/client/${params.row.id}`}>View Client</Link>
      ),
      width: 80,
    },
  ];

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">Error: {error.message}</Alert>;

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <Typography variant="h4">Clients</Typography>
        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent="flex-end">
            <Button
              onClick={toggleDrawer(true)}
              variant="contained"
              color="primary"
            >
              Add Client
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={clients}
              columns={columns}
              pageSize={25}
              rowsPerPageOptions={[10, 25, 50, 100]}
              getRowId={(row) => row.id}
            />
            {clients.length === 0 && (
              <Typography>No clients available. Add a new client.</Typography>
            )}
          </div>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        className=""
      >
        <div className="formSlide">
          <Typography variant="h4">Add New Client</Typography>
          <Suspense fallback={<div>Loading...</div>}>
            <AddClient open={isDrawerOpen} onClose={toggleDrawer(false)} />
          </Suspense>
        </div>
      </Drawer>
    </Layout>
  );
}

export default ClientList;
