import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ArticleItem from "../../components/article-item/article-item";
import ArticlesGrid from "../../components/articles-grid/article-grid";
import Layout from "../../components/layout/layout";
import EditClient from "../client-list/edit-client";
import "./client-item.css";
import { envEndPoints } from "../../config";

const ClientItem = () => {
  const { id } = useParams();
  const [client, setClient] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [isClientDrawerOpen, setIsClientDrawerOpen] = useState(false);
  const [isArticleDrawerOpen, setIsArticleDrawerOpen] = useState(false);
  const [isReportDrawerOpen, setIsReportDrawerOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [generatedFilePath, setGeneratedFilePath] = useState(null);
  const [confirmationInput, setConfirmationInput] = useState("");
  const [formData, setFormData] = useState({
    url: "",
    client_id: id,
  });
  const [reportData, setReportData] = useState({
    start_date: "",
    end_date: "",
  });
  const [reportFormErrors, setReportFormErrors] = useState({
    startDate: false,
    endDate: false,
  });

  const typeOrder = [
    "Company",
    "Regions",
    "Products",
    "Spokespersons",
    "Competitors",
    "Industries",
    "Exclusions",
  ];

  const sortTypesByCustomOrder = (types) => {
    return types.sort((a, b) => {
      return typeOrder.indexOf(a) - typeOrder.indexOf(b);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientResponse = await Axios.get(
          `${envEndPoints().baseUrl}/clients/?client_id=${id}`
        );
        const keywordsResponse = await Axios.get(
          `${envEndPoints().baseUrl}/client-keywords/?client_id=${id}`
        );
        setClient(clientResponse.data[0]);
        setKeywords(keywordsResponse.data[0]?.keywords || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  const keywordsByType = keywords.reduce((acc, keyword) => {
    if (keyword && keyword.type) {
      if (acc[keyword.type]) {
        acc[keyword.type].push(keyword.name);
      } else {
        acc[keyword.type] = [keyword.name];
      }
    }
    return acc;
  }, {});

  const totalCount = keywords.length;

  const handleDrawerClose = (drawerType) => {
    if (drawerType === "client") {
      setIsClientDrawerOpen(false);
    } else if (drawerType === "article") {
      setIsArticleDrawerOpen(false);
    } else if (drawerType === "report") {
      setIsReportDrawerOpen(false);
    }
  };

  const handleEditClick = () => {
    setIsArticleDrawerOpen(false);
    setIsClientDrawerOpen(true);
  };

  const handleReportClick = () => {
    setIsReportDrawerOpen(true);
  };

  const handleDeleteDialogToggle = () => {
    setIsDeleteDialogOpen(!isDeleteDialogOpen);
  };

  const handleAddDialogToggle = () => {
    setIsAddDialogOpen(!isAddDialogOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleReportFormChange = (event) => {
    setReportData({
      ...reportData,
      [event.target.name]: event.target.value,
    });
    setReportFormErrors({ ...reportFormErrors, [event.target.name]: false });
  };

  const handleReportSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);  // To manage loading state
    try {
        const response = await Axios.post(
            `${envEndPoints().baseUrl}/report/generate`,
            {
                client_id: id,
                start_date: reportData.start_date,
                end_date: reportData.end_date,
            },
            { responseType: 'blob' }  
        );
        setIsLoading(false);
        if (response.data) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', `Report-${new Date().toISOString()}.pptx`);  
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);  // Clean up
            alert("Report generated and download started!");
        } else {
            alert("Error: Report generation unsuccessful.");
        }
    } catch (error) {
        setIsLoading(false);
        console.error("Error generating report:", error);
        alert("Error generating report.");
    }
};

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const response = await fetch(`${envEndPoints().baseUrl}/add-article/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    setIsLoading(false);
    if (response.ok) {
      alert("Article added successfully!");
      setOpen(false);
      setFormData({ ...formData, url: "" });
    } else {
      alert("Failed to add article");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await Axios.post(
        `${envEndPoints().baseUrl}/clients/delete/`,
        {
          client_id: id,
        }
      );
      if (response.status === 200) {
        console.log("Delete successful");
        alert("Client deleted successfully!");
      } else {
        console.error("Failed to delete client", response);
        alert("Failed to delete client.");
      }
    } catch (error) {
      console.error("Error deleting client:", error);
      alert("Error during deletion process.");
    }
  };

  const handleRowClick = (article) => {
    setSelectedArticle(article);
    setIsArticleDrawerOpen(true);
    setIsClientDrawerOpen(false);
  };

  const handleConfirmationInputChange = (event) => {
    setConfirmationInput(event.target.value);
  };

  const handleDeleteConfirm = (event) => {
    event.preventDefault();
    if (confirmationInput === "DELETE") {
      console.log("Deleting...");
      setOpen(false);
    } else {
      alert("Please type 'DELETE' to confirm.");
    }
  };

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item md={3}>
          <Typography variant="h2">
            {client ? client.name : "Loading..."}
          </Typography>
        </Grid>

        <Grid item md={7}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={3}>
              Overall Sentiment
              <br />
              {client ? (
                <Chip
                  icon={
                    client.sentiment === "Positive" ? (
                      <ArrowCircleUpOutlinedIcon />
                    ) : client.sentiment === "Negative" ? (
                      <ArrowCircleDownOutlinedIcon />
                    ) : client.sentiment === "Neutral" ? (
                      <ArrowCircleRightOutlinedIcon />
                    ) : (
                      <HelpOutlineOutlinedIcon />
                    )
                  }
                  label={client.sentiment ? client.sentiment : "Unknown"}
                  className={`chip-${
                    client.sentiment
                      ? client.sentiment.toLowerCase()
                      : "unknown"
                  }-overall`}
                />
              ) : (
                <Chip label="Loading..." />
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              Owners
              <br />
              <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                {client ? (
                  client.owners.map((owner, index) => (
                    <Chip key={index} label={owner} className="chip-owners" />
                  ))
                ) : (
                  <Chip label="Loading..." />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              Industries
              <br />
              <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                {client ? (
                  client.industries.map((industry, index) => (
                    <Chip
                      key={index}
                      label={industry}
                      className="chip-clientIndustry"
                    />
                  ))
                ) : (
                  <Chip label="Loading..." />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2}>
          <Grid container justifyContent="flex-end">
            <ButtonGroup
              variant="outlined"
              size="small"
              aria-label="Small button group"
            >
              <Button onClick={handleDeleteDialogToggle}>
                <DeleteSweepOutlinedIcon />
                &nbsp;
              </Button>
              <Button onClick={handleAddDialogToggle}>
                <NewspaperOutlinedIcon />
                &nbsp;Add
              </Button>
              <Button onClick={handleEditClick}>
                <EditOutlinedIcon />
                &nbsp;Edit
              </Button>
              <Button onClick={handleReportClick}>
                <NewspaperOutlinedIcon />
                &nbsp;Report
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Typography variant="body1">
            {client ? client.description : "Loading..."}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} order={{ xs: 1, md: 2 }}>
          <ArticlesGrid
            clientId={id}
            onArticleSelect={handleRowClick}
            setSelectedArticle={setSelectedArticle}
            setIsArticleDrawerOpen={setIsArticleDrawerOpen}
            setIsClientDrawerOpen={setIsClientDrawerOpen}
          />
        </Grid>

        <Grid item xs={12} md={3} order={{ xs: 2, md: 1 }}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {client ? `${client.name} Keywords` : "Loading Keywords..."}
              </Typography>
              {client && (
                <>
                  <Typography variant="h2" sx={{ marginBottom: 2 }}>
                    {totalCount}
                  </Typography>
                  {sortTypesByCustomOrder(Object.keys(keywordsByType)).map(
                    (type, index, typesArray) => (
                      <React.Fragment key={type}>
                        <Stack spacing={1} sx={{ mb: 2 }}>
                          <Typography variant="body2" sx={{ minWidth: 100 }}>
                            {type}
                          </Typography>
                          <Stack direction="row" spacing={1} flexWrap="wrap">
                            {keywordsByType[type].map(
                              (keyword, keywordIndex) => (
                                <Chip
                                  label={keyword}
                                  key={keywordIndex}
                                  className={`chip-${type.toLowerCase()}`}
                                />
                              )
                            )}
                          </Stack>
                        </Stack>
                        {index < typesArray.length - 1 && <hr />}
                      </React.Fragment>
                    )
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={isArticleDrawerOpen}
        onClose={() => handleDrawerClose("article")}
      >
        <div className="articleSlide">
          <ArticleItem article={selectedArticle} />
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={isClientDrawerOpen}
        onClose={() => handleDrawerClose("client")}
      >
        <div className="formSlide">
          <Suspense fallback={<div>Loading...</div>}>
            <EditClient
              open={isClientDrawerOpen}
              clientId={id}
              onClose={() => handleDrawerClose("client")}
            />
          </Suspense>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={isReportDrawerOpen}
        onClose={() => handleDrawerClose("report")}
      >
        <div className="formSlide">
          <form onSubmit={handleReportSubmit}>
            <Typography variant="h6" gutterBottom>
              Generate Report
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Start Date"
                  type="date"
                  name="start_date"
                  value={reportData.start_date}
                  onChange={handleReportFormChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  error={reportFormErrors.startDate}
                  helperText={
                    reportFormErrors.startDate && "Start date is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="End Date"
                  type="date"
                  name="end_date"
                  value={reportData.end_date}
                  onChange={handleReportFormChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  error={reportFormErrors.endDate}
                  helperText={
                    reportFormErrors.endDate && "End date is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  {isLoading ? "Generating..." : "Generate Report"}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {generatedFilePath && (
                <div style={{ marginTop: 20 }}>
                  <a href={generatedFilePath} download>
                    Download Report
                  </a>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </Drawer>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogToggle}
        PaperProps={{
          component: "form",
          onSubmit: handleDeleteConfirm,
        }}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please note that deleting a client is irreversible, and will remove
            all data related to the client. Are you sure you want to
            delete&nbsp;
            <strong>{client ? client.name : "this client"}</strong>?
          </DialogContentText>
          <TextField
            required
            margin="dense"
            id="confirm"
            name="confirm"
            label="Please type DELETE to confirm"
            fullWidth
            variant="standard"
            value={confirmationInput}
            onChange={handleConfirmationInputChange}
          />
        </DialogContent>
        <DialogActions>
          <ButtonGroup variant="outlined">
            <Button onClick={handleDeleteDialogToggle}>Cancel</Button>
            <Button type="submit" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>

      <Dialog open={isAddDialogOpen} onClose={handleAddDialogToggle}>
        <DialogTitle>Add Article</DialogTitle>

        <form onSubmit={handleAddSubmit}>
          <DialogContent>
            <p>
              From time to time, some publication's SEO practices may prevent
              their results from being searchable. If you know that a
              publication has covered your client, but you can't find it in the
              search results, you can add it manually.
            </p>
            <TextField
              autoFocus
              margin="dense"
              id="url"
              label="Article URL"
              type="url"
              fullWidth
              variant="standard"
              name="url"
              value={formData.url}
              onChange={handleChange}
              required
              disabled={isLoading}
            />
          </DialogContent>
          <DialogActions>
            <ButtonGroup variant="outlined">
              <Button onClick={handleAddDialogToggle} disabled={isLoading}>
                Cancel
              </Button>
              <Button type="submit" disabled={isLoading}>
                Submit
              </Button>
            </ButtonGroup>
          </DialogActions>
        </form>
      </Dialog>
    </Layout>
  );
};

export default ClientItem;
