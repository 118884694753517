import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { envEndPoints } from "../../config";

const EditClient = ({ clientId, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [clientName, setClientName] = useState("");
  const [description, setDescription] = useState("");
  const [industries, setIndustries] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [keywordTypes, setKeywordTypes] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [newKeywordAdded, setNewKeywordAdded] = useState(false);

  useEffect(() => {
    const fetchUpdatedKeywords = async () => {
      if (newKeywordAdded) {
        try {
          const response = await axios.get(`${envEndPoints().baseUrl}/keywords/`);
          setKeywords(response.data.keywords || []);
          setNewKeywordAdded(false); // Reset the flag after fetching
        } catch (error) {
          setError("Failed to fetch updated keywords: " + error.message);
        }
      }
    };
  
    fetchUpdatedKeywords();
  }, [newKeywordAdded]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all([
          axios.get(`${envEndPoints().baseUrl}/industries/`),
          axios.get(`${envEndPoints().baseUrl}/keywords/`),
          axios.get(`${envEndPoints().baseUrl}/keyword-types/`),
          axios.get(`${envEndPoints().baseUrl}/users/`),
          axios.get(`${envEndPoints().baseUrl}/clients/?client_id=${clientId}`),
          axios.get(
            `${envEndPoints().baseUrl}/client-owners/?client_id=${clientId}`
          ),
          axios.get(
            `${envEndPoints().baseUrl}/client-keywords/?client_id=${clientId}`
          ),
          axios.get(
            `${envEndPoints().baseUrl}/client-industries/?client_id=${clientId}`
          ),
        ]);

        const [
          industriesRes,
          keywordsRes,
          keywordTypesRes,
          usersRes,
          clientRes,
          clientOwnersRes,
          clientKeywordsRes,
          clientIndustriesRes,
        ] = responses;
        setIndustries(industriesRes.data.industries || []);
        setKeywords(keywordsRes.data.keywords || []);
        setKeywordTypes(keywordTypesRes.data || []);

        // Ensure users data has firstname and surname
        const usersWithFullName = usersRes.data.users.map((user) => ({
          ...user,
          fullName:
            `${user.firstname || ""} ${user.surname || ""}`.trim() || "Unknown",
        }));

        setUsers(usersWithFullName);

        if (clientRes.data.length > 0) {
            const clientData = clientRes.data[0];
            setClientName(clientData.name || "");
            setDescription(clientData.description || "");
          } else {
            setError("No client data found");
          }

        
          const clientOwnerIds = clientOwnersRes.data.map((owner) => owner.id);
          const clientUsers = usersWithFullName.filter((user) =>
            clientOwnerIds.includes(user.id)
          );
          setSelectedUsers(clientUsers);
  
          const clientIndustries =
            clientIndustriesRes.data[0]?.industries.map((industryName) =>
              industriesRes.data.industries.find(
                (industry) => industry.name === industryName
              )
            ) || [];
          setSelectedIndustries(clientIndustries);
  
          const clientKeywordsData = clientKeywordsRes.data[0]?.keywords || [];
          setSelectedKeywords(
            keywordTypesRes.data.reduce((acc, type) => {
              const keywordsByType = clientKeywordsData
                .filter((kw) => kw.type === type.name)
                .map((kw) => ({
                  name: kw.name,
                  id:
                    keywordsRes.data.keywords.find((k) => k.name === kw.name)
                      ?.id || null,
                }));
              return { ...acc, [type.id]: keywordsByType };
            }, {})
          );
        } catch (error) {
          setError("Failed to fetch data: " + error.message);
        } finally {
          setLoading(false);
        }
      };
    fetchData();
  }, [clientId]);
          

  const checkAndAddKeyword = async (keywordName) => {
    try {
      // Check if keyword exists
      const existingKeyword = keywords.find(
        (keyword) => keyword.name === keywordName
      );
      if (existingKeyword) {
        return existingKeyword.id;
      }

      // Add new keyword
      const response = await axios.post(`${envEndPoints().baseUrl}/keywords/`, {
        name: keywordName,
      });
      const newKeyword = response.data;
      setKeywords((prevKeywords) => [
        ...prevKeywords,
        { name: keywordName, id: newKeyword.keyword_id },
      ]);
      //setNewKeywordAdded(true);
      return newKeyword.keyword_id;
    } catch (error) {
      setError("Failed to add keyword: " + error.message);
      throw error;
    }
  };

  const handleKeywordChange = (typeId) => async (event, newValue) => {
    const updatedKeywords = await Promise.all(
      newValue.map(async (keyword) => {
        const keywordName =
          typeof keyword === "string" ? keyword : keyword.name;
        const keywordId = await checkAndAddKeyword(keywordName);
        return {
          id: keywordId,
          name: keywordName,
          typeId: typeId,
        };
      })
    );

    setSelectedKeywords((prev) => ({
      ...prev,
      [typeId]: updatedKeywords,
    }));
  };

  const generateSearchQueries = async (clientId) => {
    try {
      const response = await axios.post(
          `${envEndPoints().baseUrl}/gen-search-queries/${clientId}`
      );
      console.log("Search queries generated:", response.data);
      return response.data; // Return the data to be used or stored by the caller if needed
    } catch (error) {
      console.error("Error generating search queries:", error);
      if (error.response) {
        // Log detailed response if available
        console.log("Response status:", error.response.status);
        console.log("Response headers:", error.response.headers);
        console.log("Response data:", error.response.data);
      }
      throw error; // Re-throw the error if you want to handle it further up the call stack, for example in handleSubmit
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const ownerIds = selectedUsers.map((user) => user.id);
      const industryIds = selectedIndustries.map((industry) => industry.id);
   
      // Update client details
      await axios.put(`${envEndPoints().baseUrl}/clients/${clientId}`, {
        name: clientName,
        description: description,
        ownerIds: ownerIds,
        industryIds: industryIds,
      });
   
      // Prepare keywords data
      const keywordsData = Object.entries(selectedKeywords).flatMap(
        ([typeId, keywords]) =>
          keywords.map((keyword) => ({
            keywordId: keyword.id,
            keywordTypeId: parseInt(typeId),
          }))
      );
   
      // Update client keywords
      await axios.put(`${envEndPoints().baseUrl}/client-keywords/`, {
        clientId: parseInt(clientId),
        keywords: keywordsData,
      });
   
      // Generate search queries
      const searchQueries = await generateSearchQueries(clientId);
   
      setSuccessMessage("Client updated successfully!");
      onClose(); // Close the form/modal
    } catch (error) {
      setError(
        "Failed to update client: " +
          (error.response ? error.response.data.error : error.message)
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Client Name"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            variant="outlined"
            error={!!error}
            helperText={error}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            error={!!error}
            helperText={error}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={users}
            getOptionLabel={(option) => option.fullName || "Unknown"}
            value={selectedUsers}
            onChange={(event, newValue) => setSelectedUsers(newValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} label="Client Owners" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={industries}
            getOptionLabel={(option) => option.name || ""}
            value={selectedIndustries}
            onChange={(event, newValue) => setSelectedIndustries(newValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} label="Industries" variant="outlined" />
            )}
          />
        </Grid>
        {keywordTypes.map((type) => (
          <Grid item xs={12} key={type.id}>
            <Autocomplete
              multiple
              freeSolo
              options={keywords.map((keyword) => keyword.name)}
              value={selectedKeywords[type.id].map((keyword) => keyword.name)}
              onChange={handleKeywordChange(type.id)}
              renderInput={(params) => (
                <TextField {...params} label={type.name} variant="outlined" />
              )}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Save Changes"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditClient;