import { Alert, Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { envEndPoints } from "../../config";
function AddIndustryForm() {
  const [industryName, setIndustryName] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!industryName) {
      setMessage("Industry name is required");
      setError(true);
      return;
    }

    try {
      const response = await fetch(`${envEndPoints().baseUrl}/industries/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: industryName }),
      });
      const data = await response.json();

      if (response.ok) {
        setMessage(`Industry added successfully: ID ${data.industry_id}`);
        setError(false);
        setIndustryName(""); // Clear the input on successful submission
      } else if (response.status === 409) {
        setMessage("Industry already exists");
        setError(true);
      } else {
        setMessage(data.error || "Failed to add industry");
        setError(true);
      }
    } catch (error) {
      console.error("Failed to add industry:", error);
      setMessage("Failed to add industry due to network error");
      setError(true);
    }
  };

  return (
    <Box
      component="form"
      sx={{ mt: 1 }}
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="industryName"
        label="Industry Name"
        name="industryName"
        value={industryName}
        onChange={(e) => setIndustryName(e.target.value)}
        autoFocus
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Add Industry
      </Button>
      {message && (
        <Alert severity={error ? "error" : "success"}>{message}</Alert>
      )}
    </Box>
  );
}

export default AddIndustryForm;
