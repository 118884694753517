import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import React from "react";
import { Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "../../AuthContext";
import Logo from "../../assets/mediamonitorlogo.png";
import {
  FourthListItems,
  MainListItems,
  SecondaryListItems,
  ThirdListItems,
} from "../side-nav/list-items";
import "./layout.css";

function Copyright(props) {
  return (
    <Typography color="text.secondary" align="left" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://decisioninc.com">
        Decision Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const theme = createTheme();

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function Layout({ children }) {
  const [open, setOpen] = React.useState(false); // Define the 'open' state variable

  const toggleDrawer = () => {
    // Define the 'toggleDrawer' function
    setOpen(!open);
  };

  const { authState } = useAuth();

  if (!authState.token) {
    // If the user is not logged in, redirect them to the login page
    return <Navigate to="/" replace />;
  }

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }} className="fullPage">
          <CssBaseline />
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <img src={Logo} alt="MediaMonitor" className="logo" />
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open} className="sidebar">
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              <MainListItems />
              <Divider sx={{ my: 1 }} />
              <SecondaryListItems />
              <Divider sx={{ my: 1 }} />
              <ThirdListItems />
              <Divider sx={{ my: 1 }} />
              <FourthListItems />
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {children}
              </Grid>
              <Grid item xs={12} marginTop={3}>
                <Copyright sx={{ pt: 4 }} />
              </Grid>
            </Grid>
            <Toolbar />
          </Box>
        </Box>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default Layout;
