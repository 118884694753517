// AuthContext.js
import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { envEndPoints } from "./config";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: localStorage.getItem("token") || null,
    isAuthenticated: false,
  });
  const navigate = useNavigate();
console.log(envEndPoints().baseUrl)
  const login = async (email, password) => {
    try {
      const response = await fetch(`${envEndPoints().baseUrl}/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.access_token); // Store the token
        setAuthState({ token: data.access_token, isAuthenticated: true });
        navigate("/dashboard"); // Redirect to the dashboard
      } else {
        const errorData = await response.json();
        throw new Error(
          errorData.error ||
            "Authentication failed. Please check your credentials."
        );
      }
    } catch (error) {
      throw error; // Rethrow for error boundary or further handling
    }
  };

  const logout = () => {
    localStorage.removeItem("token"); // Clear the token from local storage
    setAuthState({ token: null, isAuthenticated: false });
    navigate("/"); // Navigate back to the login page
  };

  return (
    <AuthContext.Provider value={{ authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
