import {
  Button,
  Chip,
  MenuItem,
  Paper,
  Select,
  TableContainer,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./article-grid.css";
import { envEndPoints } from "../../config";

ArticlesGrid.propTypes = {
  clientId: PropTypes.number,
  sentimentId: PropTypes.number,
  coverTypeId: PropTypes.number,
  initialPageSize: PropTypes.number,
  initialPage: PropTypes.number,
};

function ArticlesGrid({
  clientId = "",
  sentimentId = "",
  coverTypeId = "",
  initialPageSize = 25, // Make sure this matches one of the rowsPerPageOptions
  initialPage = 1, // This should be 1 if your API is 1-indexed for pages
  onArticleSelect,
  setSelectedArticle, // Accepting the function as a prop
  setIsArticleDrawerOpen, // Accepting the function as a prop
  setIsClientDrawerOpen, // Accepting the function as a prop
}) {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(initialPage - 1); // Convert to 0-index for DataGrid
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${envEndPoints().baseUrl}/articles/?client_id=${clientId}&sentiment_id=${sentimentId}&cover_type_id=${coverTypeId}&pageSize=${pageSize}&page=${
      page + 1
    }`;
    console.log("Fetching data from: ", apiUrl);

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setArticles(data.articles);
        setRowCount(data.total_count);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching articles:", error);
        setLoading(false);
      });
  }, [clientId, sentimentId, coverTypeId, pageSize, page]);

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value, 10)); // Use 10 as the radix to ensure decimal interpretation
    setPage(0); // Reset to the first page
  };

  const handleRowClick = (params) => {
    if (params && params.row) {
      setSelectedArticle(params.row);
      setIsArticleDrawerOpen(true);
      setIsClientDrawerOpen(false);
    } else {
      console.error("Row click event did not contain valid row data");
    }
  };

  // Custom pagination handlers
  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <div style={{ height: 900, width: "100%" }}>
      <TableContainer component={Paper}>
        <DataGridPro
          rows={articles}
          columns={[
            { field: "id", headerName: "ID", width: 90 },
            {
              field: "coverTypeName",
              headerName: "Type",
              width: 120,
              renderCell: (params) => {
                const chipClass =
                  params.value === "Self"
                    ? "chip-self"
                    : params.value === "Competitor"
                    ? "chip-competitor"
                    : "";
                return <Chip label={params.value} className={chipClass} />;
              },
            },
            { field: "title", headerName: "Title", flex: 1 },
            { field: "author", headerName: "Author", width: 150 },
            { field: "publicationName", headerName: "Publication", width: 150 },
            { field: "publishedDate", headerName: "Date", width: 110 },
            {
              field: "sentimentRating",
              headerName: "Sentiment",
              width: 110,
              renderCell: (params) => {
                const chipClass =
                  params.value === "Positive"
                    ? "chip-positive"
                    : params.value === "Neutral"
                    ? "chip-neutral"
                    : params.value === "Negative"
                    ? "chip-negative"
                    : "";
                return <Chip label={params.value} className={chipClass} />;
              },
            },
          ]}
          loading={loading}
          paginationMode="server"
          onRowClick={(params) => {
            if (params && params.row) {
              onArticleSelect(params.row);
            } else {
              console.error("Row click event did not contain valid row data");
            }
          }}
          rowCount={rowCount > 0 ? rowCount : undefined} // Update as per above
          // pageSize={pageSize}
          // page={page}
        />
        <div className="CustomPagination">
          <Select
            labelId="page-size-select-label"
            id="page-size-select"
            value={pageSize}
            onChange={handlePageSizeChange}
            label="Page Size"
          >
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>

          <Button
            onClick={handlePrevious}
            disabled={page === 0}
            variant="contained"
            sx={{ mr: 2 }}
          >
            Previous
          </Button>
          <Typography>Page {page + 1}</Typography>
          <Button
            onClick={handleNext}
            disabled={page * pageSize >= rowCount}
            variant="contained"
            sx={{ ml: 2 }}
          >
            Next
          </Button>
        </div>
      </TableContainer>
    </div>
  );
}

export default ArticlesGrid;
