const testEndPoints = {
  baseUrl: 'https://ec2-13-236-121-79.ap-southeast-2.compute.amazonaws.com/api',
};
const devEndpoints = {
  baseUrl: 'http://localhost:5000/api',
};

const prodEndPoints = {
  baseUrl: 'https://watterson.media-monitor.com.au/api',
};
export const envEndPoints = () => {
 return prodEndPoints;
};
