import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { envEndPoints } from "../../config";

function UpdateUserForm({ userId }) {
  const [formData, setFormData] = useState({
    firstname: "",
    surname: "",
    email: "",
    password: "",
    role_id: "",
  });
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRolesAndUserData = async () => {
      setLoading(true);
      try {
        const rolesResponse = await fetch(
          `${envEndPoints().baseUrl}/users/roles/`
        );
        const rolesData = await rolesResponse.json();
        setRoles(rolesData);

        const userResponse = await fetch(
          `${envEndPoints().baseUrl}/users/?user_id=${userId}`
        );
        const userData = await userResponse.json();

        if (userData && userData.length > 0) {
          const user = userData[0];
          setFormData({
            firstname: user.firstname || "",
            surname: user.surname || "",
            email: user.email || "",
            role_id:
              rolesData.find((role) => role.name === user.role)?.id || "", // Ensure we use the role ID from roles list matching the user role
          });
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
      setLoading(false);
    };

    if (userId) {
      fetchRolesAndUserData();
    }
  }, [userId]); // Ensure this only runs when userId changes

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    try {
      const response = await fetch(
        `${envEndPoints().baseUrl}/users/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      if (response.ok) {
        alert("User updated successfully");
      } else {
        alert("Error: " + data.error);
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Error updating user");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="firstname"
        label="First Name"
        name="firstname"
        autoComplete="firstname"
        autoFocus
        value={formData.firstname}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="surname"
        label="Last Name"
        name="surname"
        autoComplete="surname"
        value={formData.surname}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        value={formData.email}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="new-password"
        value={formData.password}
        onChange={handleChange}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="role-label">Role</InputLabel>
        <Select
          labelId="role-label"
          id="role_id"
          value={formData.role_id}
          label="Role"
          name="role_id"
          onChange={handleChange}
        >
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Update User
      </Button>
    </Box>
  );
}

export default UpdateUserForm;
