import {
  FileCopyOutlined as FileCopyOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ChangeRelevanceButtons from "./change-relevance";
import SentimentChangeButton from "./change-sentiment";
import { envEndPoints } from "../../config";

function ArticleItem({ article }) {
  const [articleDetails, setArticleDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(Date.now());

  const fetchArticleDetails = () => {
    setLoading(true);
    Axios.get(`${envEndPoints().baseUrl}/media-article/?id=${article.id}`)
      .then((response) => {
        // Clone the data to ensure it's treated as new by React
        const newData = JSON.parse(JSON.stringify(response.data));
        setArticleDetails(newData);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message || "Error fetching article details");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!article) {
      console.error("No article provided to ArticleItem.");
      return;
    }
    fetchArticleDetails();
  }, [article]); // Fetch details when article changes or on initial render

  const onSentimentChange = () => {
    console.log("Sentiment changed, refreshing article details");
    setArticleDetails();
    setLastUpdated(Date.now());
  };

  const handleMarkAsDuplicate = async () => {
    setLoading(true);
    try {
      const response = await Axios.patch(
        `${envEndPoints().baseUrl}/patcharticles/duplicate/?id=${article.id}`,
        { media_article_id: article.id }
      );
      console.log("Marked as duplicate:", response.data);
      fetchArticleDetails(); // Optionally refresh after marking
    } catch (err) {
      setError(err.response?.data?.error || "Failed to mark as duplicate");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  const themeNames =
    articleDetails && articleDetails.keyThemes
      ? Object.keys(articleDetails.keyThemes)
      : [];

  return (
    <Grid container spacing={5}>
      <Grid item md={8} className="details">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" marginBottom={2}>
              {articleDetails ? articleDetails.title : "Loading..."}
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Chip
              label={
                articleDetails ? articleDetails.coverTypeName : "Loading..."
              }
              className={`chip-${
                articleDetails
                  ? articleDetails.coverTypeName.toLowerCase()
                  : "unknown"
              }`}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography variant="body">Source</Typography>
            <Link to={articleDetails?.url} target="_blank">
              <Typography variant="body">View Article</Typography>
            </Link>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant="body">Published by</Typography>
            <Typography variant="body">
              {articleDetails
                ? `${articleDetails.publicationName} | ${articleDetails.author}`
                : "Loading..."}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant="body">Published date</Typography>
            <Typography variant="body">
              {articleDetails ? articleDetails.publishedDate : "Loading..."}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} className="ArticleAction">
        <Box display="flex" justifyContent="flex-end" spacing={2}>
          <SentimentChangeButton
            article={article}
            onSentimentChange={onSentimentChange}
          />
          <Link to={articleDetails?.url} target="_blank">
            <Button color="primary" variant="contained">
              <VisibilityOutlinedIcon />
              View Article
            </Button>
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} md={9} order={{ xs: 1, md: 2 }}>
        <Typography variant="body2">Article Summary</Typography>
        <br />
        <Typography variant="body">
          {articleDetails ? articleDetails.summary : "Loading..."}
        </Typography>
        <div className="relevance">
          <ChangeRelevanceButtons article={article} />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleMarkAsDuplicate}
            disabled={loading}
          >
            <FileCopyOutlinedIcon />
            {loading ? "Marking as Duplicate..." : "Mark as Duplicate"}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} md={3} order={{ xs: 2, md: 1 }}>
        <Typography variant="body2">Sentiment Analysis</Typography>
        <Box marginY={3}>
          <Chip
            label={
              articleDetails ? articleDetails.sentimentRating : "Loading..."
            }
            className={`chip-${
              articleDetails
                ? articleDetails.sentimentRating.toLowerCase()
                : "unknown"
            }`}
          />
        </Box>
        <Typography variant="body">
          {articleDetails ? articleDetails.sentiment : "Loading..."}
        </Typography>
        <hr className="articlesep" />
        <Typography variant="body2">Key Themes</Typography>
        <br />
        <Stack direction="row" spacing={1} flexWrap="wrap">
          {themeNames.length > 0 ? (
            themeNames.map((theme, index) => (
              <Chip key={index} label={theme} className="chip-unknown" />
            ))
          ) : (
            <Typography variant="body">No themes identified</Typography>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ArticleItem;
