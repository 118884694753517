import { Button, Drawer, Grid, Typography } from "@mui/material";
import React, { Suspense, useState } from "react";
import { useAuth } from "../../AuthContext";
import AddIndustryForm from "../../components/industries/industries-add";
import IndustriesGrid from "../../components/industries/industries-grid";
import Layout from "../../components/layout/layout";

const Industries = () => {
  const { auth, logout } = useAuth();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <Typography variant="h4">Industries</Typography>
        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent="flex-end">
            <Button
              onClick={toggleDrawer(true)}
              variant="contained"
              color="primary"
            >
              Add Industry
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <IndustriesGrid />
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        className=""
      >
        <div className="formSlide">
          <Typography variant="h4">Add Industry</Typography>
          <Suspense fallback={<div>Loading...</div>}>
            <AddIndustryForm
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
            />
          </Suspense>
        </div>
      </Drawer>
    </Layout>
  );
};

export default Industries;
