import { Drawer, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ArticleItem from "../../components/article-item/article-item";
import ArticlesGridDash from "../../components/articles-grid/article-grid-dashboard";
import Layout from "../../components/layout/layout";

const ArticlesPage = () => {
  const [mediaArticles, setMediaArticles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleArticleSelect = (article) => {
    setSelectedArticle(article);
    setIsDrawerOpen(true);
  };

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Articles</Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 700, width: "100%" }}>
            <ArticlesGridDash
              initialPageSize={25}
              initialPage={1}
              sentimentId={1}
              onArticleSelect={handleArticleSelect}
            />
          </div>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        className=""
      >
        <div className="articleSlide">
          <ArticleItem
            article={selectedArticle}
            onArticleSelect={handleArticleSelect}
          />
        </div>
      </Drawer>
    </Layout>
  );
};

export default ArticlesPage;
