import { Box, Button, CircularProgress, Drawer, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { Suspense, useEffect, useState } from "react";
import Layout from "../../components/layout/layout";
import AddUserForm from "./AddUser";
import UpdateUserForm from "./UpdateUser"; // Make sure to import the UpdateUserForm
import { envEndPoints } from "../../config";

function UserList() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null); // State to hold the selected user's ID
  const [error, setError] = useState(null);

  const toggleDrawer = (open, userId = null) => () => {
    setIsDrawerOpen(open);
    setSelectedUserId(userId); // Set the selected user ID when opening the drawer
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${envEndPoints().baseUrl}/users/`);
        const data = await response.json();
        setUsers(data.users); // Adjust this based on your actual API response structure
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
      setLoading(false);
    };

    fetchUsers();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "firstname", headerName: "First name", width: 180 },
    { field: "surname", headerName: "Last name", width: 180 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "role", headerName: "Role", width: 180 },
  ];

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <Typography variant="h4">Users</Typography>
        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent="flex-end">
            <Button
              onClick={toggleDrawer(true)}
              variant="contained"
              color="primary"
            >
              Add User
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 400, width: "100%" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <DataGridPro
                rows={users}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
                onRowClick={(params) => toggleDrawer(true, params.id)()} // Open the drawer with the update form when a row is clicked
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className="formSlide">
          {selectedUserId ? (
            <>
              <Typography variant="h4">Edit User</Typography>
              <Suspense fallback={<div>Loading...</div>}>
                <UpdateUserForm userId={selectedUserId} />
              </Suspense>
            </>
          ) : (
            <>
              <Typography variant="h4">Add User</Typography>
              <Suspense fallback={<div>Loading...</div>}>
                <AddUserForm />
              </Suspense>
            </>
          )}
        </div>
      </Drawer>
    </Layout>
  );
}

export default UserList;
