import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../AuthContext";
import Layout from "../../components/layout/layout";
import PublicationGrid from "../../components/publications-grid/publications-grid";

const Publications = () => {
  const { auth, logout } = useAuth();
  const [clients, setClients] = useState([]);
  const [mediaArticles, setMediaArticles] = useState([]);
  const [totalCountClients, setTotalCountClients] = useState(0);
  const [totalCountMediaArticles, setTotalCountMediaArticles] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <Typography variant="h4">Publications</Typography>
        </Grid>
        <Grid item xs={12}>
          <PublicationGrid />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Publications;
