import { Box, CircularProgress } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { envEndPoints } from "../../config";

function PublicationGrid() {
  const [publications, setPublications] = useState([]);
  const [loadingPublications, setLoadingPublications] = useState(true);
  const [tiers, setTiers] = useState([]);
  const [loadingTiers, setLoadingTiers] = useState(true);

  useEffect(() => {
    fetchPublications();
    fetchTiers();
  }, []);

  const fetchPublications = async () => {
    setLoadingPublications(true);
    try {
      const response = await fetch(`${envEndPoints().baseUrl}/publications/`);
      const data = await response.json();
      setPublications(data);
    } catch (error) {
      console.error("Failed to fetch publications:", error);
    }
    setLoadingPublications(false);
  };

  const fetchTiers = async () => {
    setLoadingTiers(true);
    try {
      const response = await fetch(
        `${envEndPoints().baseUrl}/publications/tiers/`
      );
      const data = await response.json();
      setTiers(data);
    } catch (error) {
      console.error("Failed to fetch tiers:", error);
    }
    setLoadingTiers(false);
  };

  const handleProcessRowUpdate = async (newRow) => {
    if (!newRow.tierId) {
      console.error("Tier ID is missing or invalid:", newRow);
      return; // Optionally show a user-friendly message or toast
    }

    try {
      const response = await fetch(
        `${envEndPoints().baseUrl}/publications/${newRow.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tier_id: newRow.tierId }),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Unknown error");
      }
      return newRow; // Consider fetching updated row from the server if necessary
    } catch (error) {
      console.error("Failed to update tier:", error);
      return newRow; // Revert the change in the UI on failure
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, editable: false },
    { field: "name", headerName: "Title", width: 200, editable: false }, // Assuming 'title' field exists
    {
      field: "tierId", // This should be 'tierId' if that's what you store in `rows`
      headerName: "Tier",
      width: 130,
      editable: true,
      type: "singleSelect",
      valueOptions: tiers.map((tier) => ({ value: tier.id, label: tier.name })),
    },
  ];

  if (loadingPublications || loadingTiers) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGridPro
        rows={publications}
        columns={columns}
        processRowUpdate={handleProcessRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

export default PublicationGrid;
